.truncate-1,
.truncate-3,
.truncate-4,
.truncate-6 {
  display: -webkit-box;
  overflow: hidden;
  padding-bottom: 0.17rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.truncate-3 {
  -webkit-line-clamp: 3;
}

.truncate-4 {
  -webkit-line-clamp: 4;
}

.truncate-6 {
  -webkit-line-clamp: 6;
}
