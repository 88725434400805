@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.2;
  }
}
