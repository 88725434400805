// Fixed styles

$paragraph-line-height: 1.6;
$paragraph-size: 1.6rem;

@mixin bodyLarge {
  font-family: $font-halyard-display-regular;
  font-size: 2.2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;
}

@mixin bodySmall {
  font-family: $font-halyard-text-book;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.4;
}

@mixin paragraph {
  font-family: $font-halyard-text-book;
  font-size: $paragraph-size;
  font-weight: 300;
  letter-spacing: 0;
  line-height: $paragraph-line-height;
}

@mixin linkLarge {
  font-family: $font-halyard-display-regular;
  font-size: 2.2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
}

@mixin link {
  font-family: $font-halyard-text-medium;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

@mixin button {
  font-family: $font-halyard-text-medium;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
}

@mixin caption {
  font-family: $font-halyard-text-medium;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4;
}

// Breakpoint styles
@mixin pageTitleLarge {
  font-family: $font-joane-regular;
  font-size: 4.8rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.1;

  @include md {
    font-size: 6.4rem;
    letter-spacing: -0.02em;
  }

  @include lg {
    font-size: 8.2rem;
  }
}

@mixin pageTitleSmall {
  font-family: $font-joane-regular;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.1;

  @include md {
    font-size: 5rem;
    letter-spacing: -0.02em;
  }

  @include lg {
    font-size: 6.4rem;
  }
}

@mixin sectionTitle {
  font-family: $font-halyard-display-regular;
  font-size: 3.6rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.1;

  @include md {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.15;
  }

  @include lg {
    font-size: 4.6rem;
  }
}

@mixin modulesTitle {
  font-family: $font-halyard-display-regular;
  font-size: 2.6rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2;

  @include lg {
    font-size: 3.2rem;
  }
}

@mixin blockquote {
  font-family: $font-joane-light;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.3;

  @include md {
    font-size: 3.2rem;
  }

  @include lg {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.15;
  }
}

@mixin focus($outline-offset: 0.2rem) {
  position: relative;
  outline: none;

  &:focus-visible::before {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    outline: 0.2rem solid $color-white;
    outline-offset: $outline-offset;
  }

  &:focus-visible::after {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    outline: 0.2rem solid $color-black;
    outline-offset: $outline-offset + 0.2rem;
  }
}

@mixin field-focus($outline-offset: 0.2rem) {
  position: relative;
  outline: none;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    outline: 0.2rem solid $color-white;
    outline-offset: $outline-offset;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    outline: 0.2rem solid $color-black;
    outline-offset: $outline-offset + 0.2rem;
  }
}

@mixin dropcap-paragraph-typography {
  &::first-letter {
    margin-right: 0.4rem;
    float: left;
    font-family: $font-joane-regular;
    font-size: 8.8rem;
    letter-spacing: -0.02rem;
    // it should be the height of 3 lines - we set it to 2.95 to prevent it breaking into 4 lines because of rounding
    line-height: $paragraph-line-height * $paragraph-size * 2.95;

    // used to align it on firefox
    @supports (-moz-appearance: none) {
      margin-top: 0.7rem;
    }
  }
}
