// Fixed styles
.bodyLargeTypography {
  @include bodyLarge;
}

.bodySmallTypography {
  @include bodySmall;
}

.paragraphTypography {
  @include paragraph;
}

.linkLargeTypography {
  @include linkLarge;
}

.linkTypography {
  @include link;
}

.buttonTypography {
  @include button;
}

.captionTypography {
  @include caption;
}

// Breakpoint styles
.pageTitleLargeTypography {
  @include pageTitleLarge;
}

.pageTitleSmallTypography {
  @include pageTitleSmall;
}

.sectionTitleTypography {
  @include sectionTitle;
}

.modulesTitleTypography {
  @include modulesTitle;
}

.blockquoteTypography {
  @include blockquote;
}
