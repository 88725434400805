.loading {
  width: 3.2rem;

  circle:nth-child(1) {
    animation: loading 1.5s infinite;
  }

  circle:nth-child(2) {
    animation: loading 1.5s infinite 0.2s;
  }

  circle:nth-child(3) {
    animation: loading 1.5s infinite 0.4s;
  }
}
