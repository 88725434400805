// Small breakpoint only
@mixin sm-only() {
  @media (max-width: ($breakpoint-md - 1)) {
    @content;
  }
}

// Medium breakpoint
@mixin md() {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

// Medium breakpoint only
@mixin md-only() {
  @media (min-width: $breakpoint-md) and (max-width: ($breakpoint-lg - 1)) {
    @content;
  }
}

// Large breakpoint
@mixin lg() {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
