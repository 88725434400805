@import 'base/fonts';
@import 'base/variables';
@import 'base/normalize';
@import 'base/mixins';
@import 'base/breakpoints';
@import 'base/animations';
@import 'base/typography';

// components
@import 'components/swiper';
@import 'components/spacings';
@import 'components/loading';
@import 'components/expandingArrow';
@import 'components/logo';
@import 'components/navigation';
@import 'components/truncate';

html,
body {
  @media (max-width: 275px) {
    overflow: clip visible;
  }
}

#__next {
  isolation: isolate;
}
