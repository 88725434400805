@import '/styles/base.scss';

.section {
  margin-bottom: $section-spacing-sm;

  @include md {
    margin-bottom: $section-spacing-md;
  }

  @include lg {
    margin-bottom: $section-spacing-lg;
  }
}

.module {
  margin-bottom: 4.8rem;

  @include md {
    margin-bottom: 6.4rem;
  }

  @include lg {
    margin-bottom: 8.8rem;
  }
}


.overflowRight {
  margin-right: calc(-1 * ($margin-sm));

  @include md {
    margin-right: calc(-1 * ($margin-md));
  }

  @include lg {
    margin-right: 0;
  }
}

.overflowLeft{
  margin-left: calc(-1 * ($margin-sm));

  @include md {
    margin-left: calc(-1 * ($margin-md));
  }

  @include lg {
    margin-left: 0;
  }
}
