// OVERRIDE SWIPER PAGINATION STYLE
.swiper-pagination-bullet-active.thumbnail img {
  outline-color: white;
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0 0.1rem;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next.thumbnail {
  opacity: 0.4;
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev.thumbnail {
  opacity: 0.4;
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next.thumbnail {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev.thumbnail {
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  transform: scale(1);
}

.swiper-pagination-fraction {
  @include button;
  color: $color-white;
  bottom: 0 !important;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.thumbnail,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet.thumbnail {
  margin: 0;
}

.light-background > button {
  color: $color-black;
}

.dark-background > button {
  color: $color-white;
}

.light-background {
  .swiper-button-disabled {
    color: $color-gray-light !important;
  }
}

.dark-background {
  .swiper-button-disabled {
    color: rgba($color-white, 0.6) !important;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0 !important;
}
