.ExpandingArrowLeft,
.ExpandingArrowRight,
.ExpandingArrowDiagonal {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: top;

  > svg {
    position: absolute;
    top: 60%;
    width: 1.3rem;
    height: 1.5rem;
    margin: 0;
    transition: transform 0.3s ease;
  }
}

.ExpandingArrowLeft {
  left: -0.2rem;
  margin-right: 0.2rem;

  > svg {
    transform: translate3d(0.3rem, -50%, 0);

    a:hover &,
    a:focus-visible & {
      transform: translate3d(0, -50%, 0);
    }
  }
}

.ExpandingArrowRight {
  margin-left: 0.2rem;

  > svg {
    transform: translate3d(-0.3rem, -50%, 0);

    a:hover &,
    a:focus-visible &,
    button:hover &,
    button:focus-visible & {
      transform: translate3d(0, -50%, 0);
    }
  }
}

.ExpandingArrowDiagonal {
  top: -0.3rem;
  margin-left: 0.2rem;

  > svg {
    top: 0;
    height: 1.2rem;
    transform: translate3d(-0.2rem, 55%, 0);

    a:hover &,
    a:focus-visible &,
    a:hover ~ .expand a &,
    a:focus-visible ~ .expand a & {
      transform: translate3d(0, 25%, 0);
    }
  }
}
