@font-face {
  font-display: block;
  font-family: 'Halyard Display Light';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('../../src/v1/assets/fonts/HalyardDisplay-Book.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: 'Halyard Display Regular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('../../src/v1/assets/fonts/HalyardDisplay-Regular.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: 'Halyard Display Medium';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('../../src/v1/assets/fonts/HalyardDisplay-SemiBold.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: 'Halyard Text Medium';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url('../../src/v1/assets/fonts/HalyardText-Medium.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: 'Halyard Text Book';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('../../src/v1/assets/fonts/HalyardText-Book.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: 'Joane T Light';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src:
    url('../../src/v1/assets/fonts/JoaneT-Light.otf') format('opentype'),
    url('../../src/v1/assets/fonts/JoaneT-Light.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Joane T Regular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src:
    url('../../src/v1/assets/fonts/JoaneT-Regular.otf') format('opentype'),
    url('../../src/v1/assets/fonts/JoaneT-Regular.woff') format('woff');
}
