@import '../base';
@import '~/v1/components/navigation/navigation.constants.module';

:root {
  $scroll-progress: calc(1 - var(--scroll-progress, 1));
  --nav-width: calc(var(--nav-width-base) + var(--nav-width-growth) * #{$scroll-progress});
  --nav-wordmark-height: calc(
    var(--nav-wordmark-height-base) + var(--nav-wordmark-height-growth) * #{$scroll-progress}
  );
  --nav-wordmark-spacing: calc(
    var(--nav-wordmark-spacing-base) + var(--nav-wordmark-spacing-growth) * #{$scroll-progress}
  );

  @include lg {
    --nav-width-base: #{$primary-lg-width};
    --nav-width-growth: #{$primary-lg-width-growth};

    --nav-wordmark-height-base: #{$wordmark-lg-height};
    --nav-wordmark-height-growth: #{$wordmark-lg-height-growth};

    --nav-wordmark-spacing-base: #{$wordmark-lg-spacing};
    --nav-wordmark-spacing-growth: #{$wordmark-lg-spacing-growth};
  }
}
